export interface BootstrapState {
  readonly isBootstrapped: boolean
  readonly pathContext: PathContext
}

export interface PathContext {
  readonly invited?: string
  readonly user?: string
  readonly teamID?: string
}
export const defaultBootstrapState = {
  isBootstrapped: false,
  pathContext: {}
}
