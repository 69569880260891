import React from "react"
import styled from "styled-components"
import { webStrings as strings, welcomeMessage } from "../assets/web_strings"
import { mq, constantStyles } from "../assets/style_consts"
import Brochure from "./brochure"
import ShareButton from "../assets/images/share-btn.png"
import { useHistory } from "react-router"
import GoogleAnalytics from "react-ga"

const {
  formStyles,
  textStyles,
  sectionStyles,
  buttonStyles,
  margin: { margin60 }
} = constantStyles
const {
  accountHasBeenCreated,
  useWithYourTeam,
  planningOnUsingCardsmithAlone,
  changeYourMind,
  gotIt,
  skipForNow
} = strings.teamCreation

interface TeamCreationShellProps {
  readonly userName: string
  readonly lastName: string
}

export const TeamCreationShell: React.FC<TeamCreationShellProps> = props => {
  const { userName, lastName, children } = props
  const history = useHistory()
  return (
    <TeamCreationShellContainer>
      <div className="form-wrapper">
        <div className="header container">
          <h1>{welcomeMessage(userName)}</h1>
          <p>{accountHasBeenCreated}</p>
        </div>
        <div className="modal-content">
          <h1>{useWithYourTeam}</h1>
          {children}
        </div>
        <div className="footer">
          <p className="bold">{planningOnUsingCardsmithAlone}</p>
          <p className="multiline">{changeYourMind}</p>
          <p className="confirm-skip">
            {gotIt}
            <span
              className="emphasis link"
              onClick={(): void => {
                GoogleAnalytics.event({
                  category: "User",
                  action: "Click - skip team creation"
                })
                history.push("/get-started")
              }}
            >
              {skipForNow}
            </span>
          </p>
        </div>
      </div>
      <Brochure />
    </TeamCreationShellContainer>
  )
}

const TeamCreationShellContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .form-wrapper {
    ${formStyles}
    ${textStyles}
    ${sectionStyles}
    ${buttonStyles}
    margin: 0 auto;
    .modal-content {
      padding-top: 24px;
      h1 {
        font-size: 1.4em;
        margin-bottom: 25px;
      }
    }
    .team-name {
      padding-bottom: 28px;
      p {
        font-size: 1.1em;
        margin-bottom: 20px;
        line-height: 120%;
      }
      p.multi-line {
        margin-bottom: 44px;
      }
      .button-wrapper {
        margin-top: 10px;
        text-align: center;
        .button {
          width: 90%;
          padding: 10px;
        }
      }
    }
    .footer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      p.multiline {
        margin-bottom: 10px;
      }
      img {
        width: 24px;
        margin-bottom: 10px;
      }
    }
    .team-name-form {
      padding-bottom: 20px;
    }
    .team-email-form {
      .button-wrapper {
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: wrap;
        margin-bottom: 20px;

        button {
          padding: 10px;
          height: max-content;
        }
        button.orange {
          width: 100%;
          margin-bottom: 5px;
        }
        button.gray {
          width: 100%;
        }
      }
    }
  }

  ${mq.desktopBrochure`
    width: 895px;
    ${margin60}
    flex-direction: row;

    .team-email-form {
      .button-wrapper {
        justify-content: space-between;
        button.orange {
          width: 35%;
        }
        button.gray {
          width: 60%;
        }
      }
    }
  `};
`
