import { SitkaModule } from "olio-sitka"
import { put, select, call } from "redux-saga/effects"
import { AppModules, AppState, sitka } from "../sitka"
import { GoogleUserData } from "../signup/signup_module"
import { SessionState, defaultSessionState, Error } from "./session_state"
import { TeamModule } from "../team/team_module"
import * as firebase from "firebase/app"
import {
  getFBUserByUid,
  getFBTeamData,
  getFBPendingInvites
} from "../../../api/client"
import "firebase/database"
import "firebase/auth"

interface ProfileObject {
  readonly given_name: string
  readonly family_name: string
}

interface UserNames {
  readonly firstName: string
  readonly lastName: string
}

export class SessionModule extends SitkaModule<SessionState, AppModules> {
  public moduleName = "session"
  public defaultState: SessionState = defaultSessionState

  // getters
  public static getSession(state: AppState): SessionState {
    return state.session
  }

  public static getSessionUserFirstName(state: AppState): string {
    return state.session.userFirstName
  }
  public static getSessionUserLastName(state: AppState): string {
    return state.session.userLastName
  }

  public getUID(state: AppState): string {
    return state.session.uid
  }

  public getDataInFlight(state: AppState): boolean {
    return state.session.dataInFlight
  }

  public getError(state: AppState): {} | null {
    return state.session.error
  }

  // setters
  public *handleSetSessionUserName(userFirstName: string): {} {
    const sessionState: SessionState = yield select(SessionModule.getSession)
    yield put(this.setState({ ...sessionState, userFirstName }))
  }

  public *handleSetFromSI(fromSI: boolean): {} {
    const sessionState: SessionState = yield select(SessionModule.getSession)
    yield put(this.setState({ ...sessionState, fromSI }))
  }

  public *handleSetUID(uid: string): {} {
    const sessionState: SessionState = yield select(SessionModule.getSession)
    yield put(this.setState({ ...sessionState, uid }))
  }

  public *handleSetSessionAuth(
    userFirstName: string,
    userLastName: string,
    uid: string,
    email: string
  ): {} {
    const session = yield select(SessionModule.getSession)
    yield put(
      this.setState({ ...session, userFirstName, userLastName, uid, email })
    )
  }

  public *handleClearSession(): {} {
    yield put(this.setState({ ...this.defaultState }))
  }

  public *handleSetDataInFlight(dataInFlight: boolean): {} {
    const session: SessionState = yield select(SessionModule.getSession)
    yield put(this.setState({ ...session, dataInFlight }))
  }

  public *handleSetError(error: Error): {} {
    const session: SessionState = yield select(SessionModule.getSession)
    yield put(this.setState({ ...session, error }))
  }

  public *handleSetRedirectPath(redirectPath: string): {} {
    const session: SessionState = yield select(SessionModule.getSession)
    yield put(this.setState({ ...session, redirectPath }))
  }

  public *handleSetIsRedirectReady(isRedirectReady: boolean): {} {
    const session: SessionState = yield select(SessionModule.getSession)
    console.log(`About to set session ${JSON.stringify(session)} state..)`)
    yield put(this.setState({ ...session, isRedirectReady }))
  }

  public *handleClearError(): {} {
    const session: SessionState = yield select(SessionModule.getSession)
    yield put(this.setState({ ...session, error: null }))
  }

  // Module Functions
  public *handleCreateRedirectPath(hasInvites: boolean): {} {
    const { bootstrap } = this.modules
    const teamName = yield select(TeamModule.getTeamName)
    const { pathContext } = yield select(bootstrap.getBootstrapState)
    const { invited } = pathContext

    console.log(
      `invited: ${invited}  hasPendingTeamInvites: ${hasInvites} teamName: ${teamName}`
    )

    if (invited || hasInvites) {
      console.log(` hasPendingTeamInvites redirection`)
      yield call(this.handleSetRedirectPath, "/get-started")
      return
    }

    console.log(
      ` hasPendingTeamInvites redirection failed teamname is ${teamName}`
    )

    if (teamName) {
      yield call(this.handleSetRedirectPath, "/invite-team")
    }
  }

  //Firebase Auth Functions
  public triggerGoogleAuthRedirect = (): void => {
    const provider = new firebase.auth.GoogleAuthProvider()
    provider.setCustomParameters({
      prompt: "select_account"
    })
    firebase.auth().signInWithRedirect(provider)
  }

  // handleGoogleAuthUser receives the result of getRedirectResult(), which is either null or a googleAuth userCredential.  If null, this app was not the target of a redirect from the googleAuth user selection api, and login is handled by either handleAuthStateChange or handleRegisterNewUser.
  public *handleGoogleAuthUser(
    userCred: firebase.auth.UserCredential | null
  ): {} {
    if (!userCred) {
      return
    }

    const { signup } = this.modules
    if (userCred.user && userCred.user.email) {
      const { uid, email } = userCred.user
      // 'fbUser' is a pre-existing database /user associated with the unique 'user' id
      const fbUser = yield call(getFBUserByUid, uid)
      if (fbUser) {
        // this userCred.user.email is already associated with an existing cardsmith account
        this.logOutWithError("USER ALREADY EXISTS")
        return
      }

      // parse data from the google user credential to create a new cardsmith account
      const { firstName, lastName } = this.getProfileNames(userCred)
      const signupData: GoogleUserData = {
        firstName,
        lastName,
        uid,
        email
      }

      const formValues = {
        firstName,
        lastName,
        email
      }
      signup.handleSetNewFormState(formValues)
      signup.handleRegisterNewGoogleUser(signupData)
      yield call(this.handleSetDataInFlight, false)
    }
  }

  // check if user credential was returned from a signInByRedirect() googleOAuth login
  public *handleAttemptSignInFromGoogleAuth(): {} {
    const promise = firebase.auth().getRedirectResult()
    const userCred = yield promise
    if (!!userCred && !!userCred.user) {
      return yield call(this.handleGoogleAuthUser, userCred)
    } else {
      yield call(this.handleSetDataInFlight, false)
    }

    return false
  }

  public getProfileNames(userCred: firebase.auth.UserCredential): UserNames {
    if (
      userCred &&
      userCred.additionalUserInfo &&
      userCred.additionalUserInfo.profile
    ) {
      const profile = userCred.additionalUserInfo.profile as ProfileObject
      const { given_name: firstName, family_name: lastName } = profile
      return { firstName, lastName }
    }

    // fallback if no given_name or family_name
    if (userCred.user && userCred.user.displayName) {
      const names = userCred.user.displayName.split(" ")
      return { firstName: names[0], lastName: names[1] || "" }
    }

    return {
      firstName: "",
      lastName: ""
    }
  }

  public *handleAuthStateChange(user: firebase.User | null): {} {
    const { bootstrap, signup, team } = sitka.getModules()
    // 'user' refers to the firebase.auth identity registered with the cardsmith app
    if (user) {
      const { uid, email } = user
      // 'fbUser' is a pre-existing database /user associated with the unique 'user' id
      const fbUser = yield call(getFBUserByUid, uid)
      if (fbUser) {
        const { name } = fbUser
        const names = name.split(" ")
        const signInState = {
          firstName: names[0],
          lastName: names[1],
          email: fbUser.email.toLowerCase()
        }
        this.handleSetSessionAuth(
          names[0],
          names[1],
          uid,
          email ? email.toLowerCase() : ""
        )
        signup.handleSetNewFormState(signInState)

        try {
          const teamMeta = yield call(getFBTeamData, uid)

          if (!!teamMeta) {
            const { key: teamKey, title: teamName } = teamMeta
            if (teamKey) {
              yield call(team.handleSetTeamKey, teamKey)
              yield call(team.handleSetTeamName, teamName)
            }
          }
        } catch (exception) {
          console.log(exception)
        }
      }

      if (email != null) {
        const hasPending = yield call(getFBPendingInvites, email)
        const { hasInvites } = hasPending
        yield call(this.handleCreateRedirectPath, hasInvites)
      } else {
        yield call(this.handleCreateRedirectPath, false)
      }
      this.handleSetIsRedirectReady(true)
    }
    yield call(this.handleSetDataInFlight, false)
    bootstrap.handleSetIsBootstrapped(true)
  }

  public logOut = (): void => {
    const { team, signup } = this.modules

    firebase.auth().signOut()
    this.handleClearSession()
    team.handleClearTeamState()
    signup.handleClearSignupState()
  }

  public *handleLogOut(): {} {
    const { team, signup } = this.modules
    const promise = firebase.auth().signOut()

    yield promise
    yield call(this.handleClearSession)
    yield call(team.handleClearTeamState)
    yield call(signup.handleClearSignupState)
  }

  public logOutWithError = (error: Error): void => {
    this.logOut()
    this.handleSetError(error)
  }
  // Private Firebasae Functions
  // Read Firebase value from firebase database ref object
  private get(fbRef: firebase.database.Reference): Promise<string> {
    return new Promise((resolve, reject) => {
      fbRef.once("value", ss => resolve(ss.val()), reject)
    })
  }
}
