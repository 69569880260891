import firebase from "firebase/app"
import { TeamCreationPayload } from "../redux/sitka/team/team_module"
import "firebase/database"
import "firebase/auth"

interface FBUser {
  readonly email: string
  readonly intercomSecret: string
  readonly name: string
  readonly since: number
}

interface TeamData {
  readonly key: string
  readonly title: string
}

interface PendingInvites {
  readonly hasInvites: boolean
}

export const getFBRef = (
  fbRef: firebase.database.Reference
): Promise<FBUser> => {
  return new Promise((resolve, reject) => {
    fbRef.once("value", ss => resolve(ss.val()), reject)
  })
}

export function* getFBUserByUid(uid: string): {} {
  const ref = firebase.database().ref(`users/${uid}`)
  const fbUser = yield getFBRef(ref)
  return fbUser
}

export const getFBPendingInvites = (email: string): Promise<PendingInvites> => {
  return new Promise((resolve, reject) => {
    const ref = firebase
      .database()
      .ref("pendingTeamInvites")
      .child(email.toLowerCase().replace(/\./g, ","))

    ref.on("value", pendingInvites => {
      let status = false
      if (pendingInvites.val() != null) {
        console.log(`pendingInvites: ${JSON.stringify(pendingInvites.val())}`)
        let pendingInviteList = pendingInvites.val()
        pendingInvites.forEach(ss => {
          const invite = ss.val()
          console.log(`Invite ${ss.key} has status ${invite.status}`)
          if (invite.status == null || invite.status != "DONE") {
            status = true
          }
        })
        resolve({ hasInvites: status })
      } else {
        console.log(`pendingInvites: ${JSON.stringify(pendingInvites)}`)
        console.log(`pendingInvites: ${pendingInvites}`)
        resolve({ hasInvites: false })
      }
    })
  })
}

export const getFBTeamData = (uid: string): Promise<TeamData> => {
  return new Promise((resolve, reject) => {
    const ref = firebase.database().ref("teams")
    ref
      .orderByChild("parent")
      .equalTo(uid)
      .on("value", returnValue => {
        const snapshots = Array.isArray(returnValue)
          ? returnValue
          : [returnValue]
        const snapshot = snapshots[0]
        if (!snapshot) {
          // todo - a better error messaging solution
          reject("There was an error retrieving team data")
          return
        }
        if (snapshot.key && snapshot.val()) {
          const val = snapshot.val()
          const key = Object.keys(val)[0]
          const title = val[key].info.title
          const teamData = {
            key,
            title
          }
          resolve(teamData)
          return
        }

        // todo - a better error messaging solution
        reject("No team data was found")
      })
  })
}

export const buildTeamCreationPayload = (
  teamName: string,
  uid: string
): TeamCreationPayload => {
  return {
    info: { title: teamName },
    parent: uid,
    users: { [uid]: { role: "owner" } }
  }
}
