import React, { useEffect } from "react"
import { connect } from "react-redux"
import { sitka, AppState } from "../../common/redux/sitka/sitka"
import {
  TeamModule,
  CreateTeamValues
} from "../../common/redux/sitka/team/team_module"
import { SessionState } from "../../common/redux/sitka/session/session_state"
import { webStrings as strings } from "../assets/web_strings"
import { constantStyles } from "../assets/style_consts"
import { TeamCreationShell } from "./team_creation_shell"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import { useHistory } from "react-router-dom"
import GoogleAnalytics from "react-ga"

const { StyledInput } = constantStyles
const {
  collaborateAnywhereAnytime,
  description,
  teamNameInputLabel,
  teamNameInputPlaceholder,
  teamNameButtonText
} = strings.teamCreation

const { required } = strings.signupErrors

const yupSchema = Yup.object({
  teamName: Yup.string().required(` ${required}`)
})

interface TeamNameFormProps {
  readonly teamModule: TeamModule
  readonly session: SessionState
}

interface FormContentProps {
  readonly onCreateTeam: (values: CreateTeamValues) => void
  readonly teamName: string
}

const FormContent: React.FC<FormContentProps> = ({
  onCreateTeam,
  teamName
}) => (
  <div className="team-name">
    <p>{collaborateAnywhereAnytime}</p>
    <p className="multi-line">{description}</p>
    <Formik
      initialValues={{
        teamName
      }}
      onSubmit={(values): void => {
        onCreateTeam({ teamName })
      }}
    >
      <Form className="team-name-form">
        <div className="button-wrapper">
          <button type="submit" className="orange button">
            {teamNameButtonText}
          </button>
        </div>
      </Form>
    </Formik>
  </div>
)

const TeamNameForm: React.FC<TeamNameFormProps> = ({ teamModule, session }) => {
  const { userFirstName, userLastName, error } = session
  const { handleCreateTeam } = teamModule
  const history = useHistory()

  useEffect(() => {
    GoogleAnalytics.pageview("create-team")

    if (error === "USER ALREADY EXISTS") {
      history.push("/signup")
    }
  }, [error, history])

  const onCreateTeam = (values: CreateTeamValues): void => {
    handleCreateTeam(values)
    history.push("/invite-team")
  }

  return (
    <TeamCreationShell userName={userFirstName} lastName={userLastName}>
      <FormContent
        onCreateTeam={onCreateTeam}
        teamName={userFirstName + " " + userLastName + "'s Team"}
      />
    </TeamCreationShell>
  )
}

export default connect((state: AppState) => {
  const modules = sitka.getModules()
  const { session } = state
  const { team: teamModule } = modules

  return {
    session,
    teamModule
  }
})(TeamNameForm)
