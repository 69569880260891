import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import ReactSwipe from "react-swipe"
import { SessionState } from "../../common/redux/sitka/session/session_state"
import { TeamState } from "../../common/redux/sitka/team/team_module"
import { sitka, AppState } from "../../common/redux/sitka/sitka"
import { appColors } from "../assets/app_colors"
import { webStrings as strings, getStartedHeader } from "../assets/web_strings"
import { mq, constantStyles } from "../assets/style_consts"
import leftButton from "../assets/images/cs-slider-lft.png"
import rightButton from "../assets/images/cs-slider-rt.png"
import onboard1 from "../assets/images/onboard-gifs/onboard-1-create-board.gif"
import onboard2 from "../assets/images/onboard-gifs/onboard-2-connect.gif"
import onboard3 from "../assets/images/onboard-gifs/onboard-3-card-detail.gif"
import onboard4 from "../assets/images/onboard-gifs/onboard-4-card-options.gif"
import onboard5 from "../assets/images/onboard-gifs/onboard-5-grid.gif"
import onboard6 from "../assets/images/onboard-gifs/onboard-6-totals.gif"
import onboard7 from "../assets/images/onboard-gifs/onboard-7-sharing.gif"
import onboard8 from "../assets/images/onboard-gifs/onboard-8-comment.gif"
import onboard9 from "../assets/images/onboard-gifs/onboard-9-activity.gif"
import { RedirectModule } from "../../common/redux/sitka/redirect/redirect_module"
import GoogleAnalytics from "react-ga"

const {
  textStyles,
  sectionStyles,
  buttonStyles,
  margin: { margin60 }
} = constantStyles
const {
  yourTeamHasBeenInvited,
  quickTipsSubheader,
  buttonText
} = strings.getStarted
const carouselPanels = [
  onboard1,
  onboard2,
  onboard3,
  onboard4,
  onboard5,
  onboard6,
  onboard7,
  onboard8,
  onboard9
]

interface GetStartedInfoProps {
  readonly session: SessionState
  readonly redirectModule: RedirectModule
  readonly team: TeamState
}

const Carousel: React.FC<{}> = () => {
  const [selectedSlide, handleSelectSlide] = useState(0)

  let swipeEl = useRef(null) as any

  const goToSlideByIndex = (i: number, el: any): void => {
    GoogleAnalytics.event({
      category: "User",
      action: "Carousel - navigate to slide " + i
    })
    handleSelectSlide(i)
    el.slide(i)
  }

  const calculateNextSlideIndex = (
    currentSlideIndex: number,
    isNext: boolean,
    slideCount: number
  ): number => {
    if (isNext) {
      return currentSlideIndex === slideCount - 1 ? 0 : currentSlideIndex + 1
    } else {
      return currentSlideIndex === 0 ? slideCount - 1 : currentSlideIndex - 1
    }
  }

  const handleNavgiate = (i: number, isNext: boolean): void => {
    const carouselAction = isNext
      ? "next"
      : "previous" + " slide, from current: " + i
    GoogleAnalytics.event({
      category: "User",
      action: "Carousel - to " + carouselAction + " (Slide " + i + " )"
    })
    const slideToSet = calculateNextSlideIndex(i, isNext, carouselPanels.length)
    handleSelectSlide(slideToSet)
    isNext ? swipeEl.next() : swipeEl.prev()
  }

  return (
    <div className="carousel-container">
      <button onClick={(): void => handleNavgiate(selectedSlide, false)}>
        <img src={leftButton} alt="Arrow icon for navigating carousel" />
      </button>
      <div className="carousel-wrapper">
        <ReactSwipe
          className="carousel"
          ref={(el): void => {
            swipeEl = el
          }}
        >
          {carouselPanels.map((imgSrc, i) => {
            const { header, content } = strings.carouselContent[i]
            return (
              <div className="carousel-panel" key={i}>
                <div className="carousel-slide-header">
                  <h1>{header}</h1>
                  <p>{content}</p>
                </div>
                <img
                  src={imgSrc}
                  alt={`Gif of Cardsmith user tutorial part ${i + 1}`}
                />
              </div>
            )
          })}
        </ReactSwipe>
        <div className="carousel-menu">
          {carouselPanels.map((_imgSrc, i) => {
            const className = `carousel-item${
              i === selectedSlide ? " selected" : ""
            }`
            return (
              <div
                className={className}
                key={i}
                onClick={(): void => goToSlideByIndex(i, swipeEl)}
              />
            )
          })}
        </div>
      </div>
      <button onClick={(): void => handleNavgiate(selectedSlide, true)}>
        <img src={rightButton} alt="Arrow icon for navigating carousel" />
      </button>
    </div>
  )
}

const GetStartedInfo: React.FC<GetStartedInfoProps> = ({
  session,
  redirectModule,
  team
}) => {
  useEffect(() => {
    GoogleAnalytics.pageview("get-started")
  }, [])
  const { invitesSent } = team
  const showInvitedText = invitesSent
  const { handleRedirectUserToCS } = redirectModule
  const { userFirstName } = session
  return (
    <GetStartedInfoContainer showInvitedText={showInvitedText}>
      <div className="header container">
        <h1 className="emphasis">{getStartedHeader(userFirstName)}</h1>
        {showInvitedText && <p className="bold">{yourTeamHasBeenInvited}</p>}
        <h1>{quickTipsSubheader}</h1>
      </div>
      <Carousel />
      <div className="button-wrapper">
        <button
          className="orange button"
          onClick={(): void => handleRedirectUserToCS()}
        >
          {buttonText}
        </button>
      </div>
    </GetStartedInfoContainer>
  )
}
interface ContainerProps {
  readonly showInvitedText: boolean
}
const GetStartedInfoContainer = styled.div<ContainerProps>`
  ${textStyles}
  ${sectionStyles}
  ${buttonStyles}
  background: ${appColors.signupModalBackground};
  padding: 32px 16px;
  min-height: 100vh;

  .header {
 
    .bold { margin-bottom: 1em; }
    padding-bottom: 5px;
  }

  .button-wrapper {
    text-align: center;
    button {
      width: 70%;
      padding: 10px;
      text-transform: none;
    }
  }
  .carousel-container {
    max-width: calc(100vw - 48px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 16px auto 0;

    button {
      background:${appColors.signupModalBackground};
      border: 0;
      padding: 0;
    }
    .carousel-wrapper {
      width: calc(100% - 60px);
      .carousel-panel {
        img {
          width: 100%;
          max-width: 726px;
        }
      }
      .carousel-menu {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .carousel-item {
          height: 15px;
          width: 15px;
          min-width: 15px;
          border-radius: 100%;
          border: 2px solid ${appColors.signupModalGoogleButtonBorder};
          margin: 10px 5px 18px 5px;
        }
        .carousel-item.selected {
          background: ${appColors.signupModalGoogleButtonBorder};
        }
      }
    }
    .carousel-slide-header {
      max-width: 100%;

      text-align: center;
      h1 {
        font-size: 1.2em;
        color: ${appColors.getStartedEmphasisText};
      }
      p {
        font-size: 0.92em;
        margin-top: 6px;
        margin-bottom: 18px;
      }
    }
  }

  ${mq.desktopBrochure`
    ${margin60}
    width: 895px;
    padding: 32px;
    min-height: initial;
    .button-wrapper {
      text-align: center;
      button {
        width: 50%;
      }
    }

    .carousel-container {
      justify-content: space-between;
      margin: 16px 0 0 0;
      .carousel-wrapper {
        width: 726px;
      }
    }
  `}
`

export default connect((state: AppState) => {
  const modules = sitka.getModules()
  const { session, team } = state
  const { redirect: redirectModule } = modules
  return {
    session,
    redirectModule,
    team
  }
})(GetStartedInfo)

// export default connect((state: AppState) => {
//   const modules = sitka.getModules()
//   const { team, session } = state
//   const { team: teamModule } = modules

//   return {
//     team,
//     session,
//     teamModule
//   }
// })(TeamInviteForm)
