import { SitkaModule } from "olio-sitka"
import { AppModules } from "../sitka"
import * as firebase from "firebase/app"
import "firebase/auth"
import GoogleAnalytics from "react-ga"

const cardsmithURL = process.env.REACT_APP_CARDSMITH_APP_URL
const cloudFunctionUrl = process.env.REACT_APP_CLOUD_FUNCTION_URL

export class RedirectModule extends SitkaModule<{}, AppModules> {
  public moduleName = "redirect"
  public defaultState = {}

  public handleRedirectUserToCS(): void {
    GoogleAnalytics.event({
      category: "User",
      action: "Click - lets do this! redirect to CS"
    })
    const { session } = this.modules
    session.handleSetDataInFlight(true)
    this.getFirebaseIdToken()
  }

  public receiveMsg(event: MessageEvent): void {
    if (event.origin !== cardsmithURL) {
      return
    }

    const obj = JSON.parse(event.data)
    if (obj.signOut) {
      this.signOutOfFirebase()
    }
    if (obj.redirect) {
      RedirectModule.signOutOfFirebaseAndRedirect()
    }
  }

  private static isIFrame = (
    input: HTMLElement | null
  ): input is HTMLIFrameElement => input !== null && input.tagName === "IFRAME"

  public sendMessageAcrossIframe(obj: {}): void {
    const frame = document.getElementById("app-iframe")
    if (RedirectModule.isIFrame(frame) && frame.contentWindow && cardsmithURL) {
      frame.contentWindow.postMessage(JSON.stringify(obj), cardsmithURL)
    }
  }

  public signOutOfFirebase(redirectUrl?: string): void {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
        if (redirectUrl) {
          this.redirectTo(redirectUrl)
        }
      })
      .catch(error => {
        // todo: handle error
        console.log("error signing out of firebase", error)
      })
  }

  private static signOutOfFirebaseAndRedirect(): void {
    firebase
      .auth()
      .signOut()
      .then(function() {
        // Sign-out successful, redirect to main site
        if (cardsmithURL) {
          window.location.href = cardsmithURL
        }
      })
      .catch(function(error) {
        // todo: handle error
        console.log("there was an error signing user out of firebase: ", error)
      })
  }

  public getFirebaseIdToken(): void {
    const currentUser = firebase.auth().currentUser
    if (!currentUser) return

    currentUser
      .getIdToken(/* forceRefresh */ true)
      .then(idToken => {
        // Send token to your backend via HTTPS
        this.mintCustomToken(idToken)
      })
      .catch(error => {
        // todo: handle error
        console.log(
          "there was an error while retrieving a user id token: ",
          error
        )
      })
  }

  public mintCustomToken(idToken: string): void {
    const fetchUrl = cloudFunctionUrl ? cloudFunctionUrl : ""
    fetch(fetchUrl, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    })
      .then(response => response.json())
      .then(json => {
        this.signOutOfFirebase(
          process.env.REACT_APP_CARDSMITH_APP_URL +
            "/redirect.html?t=" +
            json.customToken
        )
      })
  }

  public redirectTo(url: string): void {
    window.location.href = url
  }
}
