import React from "react"
import styled from "styled-components"
import { appColors } from "../assets/app_colors"
import { webStrings as strings } from "../assets/web_strings"
import { mq, constantStyles } from "../assets/style_consts"
import brochureImage from "../assets/images/brochure.png"

const { textStyles } = constantStyles
const {
  yourFreeCardSmithAccount,
  comesWithA,
  freeTrial,
  ofCardsmithPro,
  professionalPerks,
  perks,
  afterTrial,
  optionForProfessional
} = strings.brochureContent
const Brochure: React.FC<{}> = () => {
  return (
    <BrochureContainer>
      <div className="header">
        <p className="all-caps">{yourFreeCardSmithAccount}</p>
        <p>{comesWithA}</p>
        <p className="all-caps emphasis">{freeTrial}</p>
        <p className="emphasis">{ofCardsmithPro}</p>
      </div>
      <div className="image-container">
        <img src={brochureImage} alt="People icons linked to a computer" />
      </div>
      <div className="perks">
        <p className="bold emphasis">{professionalPerks}</p>
        <ul>
          {perks.map(perk => (
            <li key={perk}>{perk}</li>
          ))}
        </ul>
      </div>
      <div className="footer">
        <p>
          <span className="bold">{afterTrial}</span>
          {optionForProfessional}
        </p>
      </div>
    </BrochureContainer>
  )
}

const BrochureContainer = styled.div`
  ${textStyles}
  width: 100%;
  margin: 0 auto;
  padding: 48px;
  background: ${appColors.signUpInfoBackground};

  .all-caps {
    text-transform: uppercase;
  }
  .header {
    > * {
      font-size: 1.1em;
      text-align: center;
      font-weight: bold;
    }
  }
  .image-container {
    margin: 20px 0;
    width: 100%;
    img {
      max-width: 100%;
    }
  }
  .perks {
    padding: 0 28px;
    p.bold.emphasis {
      font-size: 1.2em;
    }
    ul {
      padding: 0;
    }
    li {
      font-size: 0.9em;
      color: ${appColors.signupModalText};
      list-style: none;
      &:before {
        content: "- ";
      }
    }
  }
  .footer {
    padding: 0 28px;
    p {
      font-size: 0.9em;
    }
  }
  ${mq.desktop`
    width: 480px;
  `};
  ${mq.desktopBrochure`
    padding: 48px 20px;
    width: 415px;
  `};
`

export default Brochure
