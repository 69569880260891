import React, { useEffect } from "react"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import { connect } from "react-redux"
import { sitka, AppState } from "../../common/redux/sitka/sitka"
import { SignupModule } from "../../common/redux/sitka/signup/signup_module"
import { SessionModule } from "../../common/redux/sitka/session/session_module"
import styled from "styled-components"
import { webStrings as strings } from "../assets/web_strings"
import { appColors } from "../assets/app_colors"
import { mq, constantStyles } from "../assets/style_consts"
import googleIcon from "../assets/images/google-icon.png"
import "firebase/auth"
import { useHistory } from "react-router"
import { Error } from "../../common/redux/sitka/session/session_state"
import { errorMessage } from "./utils"
import GoogleAnalytics from "react-ga"

const redirectUrl = process.env.REACT_APP_CARDSMITH_APP_URL

const {
  firstName,
  lastName,
  email,
  password,
  signUp,
  googleSignUp,
  byClicking,
  tos,
  and,
  privacyPolicy,
  alreadyAUser
} = strings.signupFormLabels

const { required, passwordMin } = strings.signupErrors
const {
  header,
  andGetA,
  freeTrial,
  ofCardsmithPro,
  or,
  signUpEmail
} = strings.signupHeader
const {
  termsOfService: termsOfServiceUrl,
  privacyPolicy: privacyPolicyUrl
} = strings.links
const {
  formStyles,
  StyledInput,
  textStyles,
  sectionStyles,
  buttonStyles,
  margin: { margin60 }
} = constantStyles

interface SignupFormProps {
  readonly signupModule: SignupModule
  readonly invitedEmail?: string
  readonly sessionModule: SessionModule
  readonly uid: string
  readonly error: Error
  readonly redirectPath: string
  readonly isRedirectReady: boolean
}

const yupSchema = Yup.object({
  firstName: Yup.string().required(required),
  lastName: Yup.string().required(required),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Required"),
  password: Yup.string()
    .required(passwordMin)
    .min(6, passwordMin)
})

const redirectToCS = (): void => {
  if (redirectUrl) {
    GoogleAnalytics.event({
      category: "User",
      action: "Click - already a user"
    })
    window.location.href = redirectUrl
  }
}

const SignupForm: React.FC<SignupFormProps> = ({
  uid,
  signupModule,
  invitedEmail,
  sessionModule,
  error,
  redirectPath,
  isRedirectReady
}) => {
  const { handleRegisterNewUser } = signupModule
  const { handleSetDataInFlight, handleSetFromSI } = sessionModule

  const history = useHistory()

  useEffect(() => {
    GoogleAnalytics.pageview("sign-up")

    if (isRedirectReady && !error) {
      console.log(`Pushing redirect ${redirectPath}`)
      history.push(redirectPath)
    }
  }, [isRedirectReady, history, error])

  const onGoogleAuthClick = (): void => {
    GoogleAnalytics.event({ category: "User", action: "Click - googleauth" })
    handleSetFromSI(true)
    history.push("/googleauth")
  }

  return (
    <SignupWrapper>
      <div className="pre-form-group">
        <div className="header container">
          <h1>{header}</h1>
          <p>
            {andGetA} <span className="emphasis">{freeTrial}</span>
            {ofCardsmithPro}
          </p>
        </div>
        <div className="google-oauth container">
          <button onClick={onGoogleAuthClick}>
            <img src={googleIcon} alt="Google icon" />
            {googleSignUp}
          </button>
          <p className="or-text">{or}</p>
        </div>
        <div className="second-header">
          <h1>{signUpEmail}</h1>
          {error && <p className="error-message">{errorMessage(error)}</p>}
        </div>
      </div>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          password: "",
          email: `${invitedEmail ? invitedEmail : ""}`
        }}
        validationSchema={yupSchema}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={(values): void => {
          handleSetDataInFlight(true)
          handleRegisterNewUser(values)
        }}
      >
        <Form autoComplete="on">
          <StyledInput>
            <label htmlFor="email">
              {email}
              <span className="error-message">
                <ErrorMessage name="email" />
              </span>
            </label>
            <Field name="email" type="text" className="input-field" />
          </StyledInput>
          <div className="two-column-group">
            <StyledInput>
              <label htmlFor="firstName">
                {firstName}
                <span className="error-message">
                  <ErrorMessage name="firstName" />
                </span>
              </label>
              <Field name="firstName" type="text" className="input-field" />
            </StyledInput>
            <StyledInput>
              <label htmlFor="lastName">
                {lastName}
                <span className="error-message">
                  <ErrorMessage name="lastName" />
                </span>
              </label>
              <Field name="lastName" type="text" className="input-field" />
            </StyledInput>
          </div>
          <StyledInput>
            <label htmlFor="password">
              {password}
              <span className="error-message">
                <ErrorMessage name="password" />
              </span>
            </label>
            <Field name="password" type="password" className="input-field" />
          </StyledInput>
          <p className="tos-consent">
            {byClicking}
            <span className="bold">
              <a href={termsOfServiceUrl} target="_blank">
                {tos}
              </a>
            </span>
            {and}{" "}
            <span className="bold">
              <a href={privacyPolicyUrl} target="_blank">
                {privacyPolicy}
              </a>
            </span>
          </p>
          <button className="orange button" type="submit">
            {signUp}
          </button>
        </Form>
      </Formik>
      <div className="footer">
        <p className="emphasis link" onClick={(): void => redirectToCS()}>
          {alreadyAUser}
        </p>
      </div>
    </SignupWrapper>
  )
}

export default connect((state: AppState) => {
  const modules = sitka.getModules()
  return {
    signupModule: modules.signup,
    invitedEmail: state.bootstrap.pathContext.invited,
    sessionModule: modules.session,
    uid: state.session.uid,
    error: state.session.error,
    redirectPath: state.session.redirectPath,
    isRedirectReady: state.session.isRedirectReady
  }
})(SignupForm)

const SignupWrapper = styled.div`
  ${formStyles}
  ${textStyles}
  ${sectionStyles}
  ${buttonStyles}
  min-height: 100vh;

  border-radius: 3px;
  a {
    text-decoration: none;
    color: inherit;
  }

  .google-oauth {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    height: 90px;

    button {
      width: 100%;
      height: 46px;
      background: ${appColors.signupModalBackground};
      border: 2px solid ${appColors.signupModalGoogleButtonBorder};
      border-radius: 3px;
      color: ${appColors.signupModalText};
      font-size: 1em;
      min-height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        display: inline;
        height: 24px;
        width: 24px;
        margin-right: 32px;
      }
    }
    p.or-text {
      width: 60px;
      position: relative;
      background: ${appColors.signupModalBackground};
      top: 8px;
      align-self: center;
      z-index: 10;
      font-size: 1.4em;
      text-align: center;
    }

  }

  .second-header {
    padding-top: 12px;
    p, h1 {
      text-align: center;
    }
    p {
      font-size: .9em;
    }
    h1 {
      font-weight: 400;
      letter-spacing: 0.5px;
      margin-bottom: 5px;
      font-size: 1.3em;
    }
  }

  form {
    padding-bottom: 20px;
    text-align: center;
    .two-column-group {
      display: flex;
      flex-direction: column;
      > * {
        width: 100%;
      }
    }
    p.tos-consent {
      margin: 0 auto 15px auto;
      font-size: 0.8em;
    }

    button {
      justify-self: center;
      padding: 10px 60px;
    }
  }

  ${mq.desktop`
    ${margin60}
    min-height: initial;

    form {
      .two-column-group {
        flex-direction: row;
        justify-content: space-between;
        > * {
          width: 49%;
        }
      }
    }
  `}
`
