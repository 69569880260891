import React, { useEffect } from "react"
import { useHistory } from "react-router"
import { connect } from "react-redux"
import { SessionState } from "../../common/redux/sitka/session/session_state"
import { SessionModule } from "../../common/redux/sitka/session/session_module"
import { sitka, AppState } from "../../common/redux/sitka/sitka"
import SignupForm from "../components/signup_form"
import "firebase/auth"

interface GoogleAuthProps {
  readonly session: SessionState
  readonly sessionModule: SessionModule
}

const GoogleAuth: React.FC<GoogleAuthProps> = ({ sessionModule, session }) => {
  const { fromSI, uid, error, redirectPath } = session
  const history = useHistory()

  useEffect(() => {
    if (fromSI) {
      sessionModule.triggerGoogleAuthRedirect()
    }
  }, [fromSI])

  useEffect(() => {
    if (uid && !error) {
      // history.push("/create-team")
      history.push(redirectPath)
    } else {
      if (error) {
        history.push("/signup")
      }
    }
  }, [uid, history])

  return fromSI ? <SignupForm /> : <div />
}

export default connect((state: AppState) => {
  const modules = sitka.getModules()
  return {
    sessionModule: modules.session,
    session: state.session
  }
})(GoogleAuth)
