export interface SessionState {
  readonly userFirstName: string
  readonly userLastName: string
  readonly uid: string
  readonly email: string
  readonly dataInFlight: boolean
  readonly error: Error
  readonly fromSI: boolean
  readonly redirectPath: string
  readonly isRedirectReady: boolean
}

export type Error = "USER ALREADY EXISTS" | null

export const defaultSessionState: SessionState = {
  userFirstName: "",
  userLastName: "",
  uid: "",
  email: "",
  dataInFlight: false,
  error: null,
  fromSI: false,
  redirectPath: "/create-team",
  isRedirectReady: false
}
