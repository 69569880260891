export interface SignupState {
  readonly signupFormData: SignupStateValues
}

export interface SignupFormData {
  readonly firstName: string
  readonly lastName: string
  readonly password: string
  readonly email: string
}

export interface SignupStateValues {
  readonly firstName: string
  readonly lastName: string
  readonly email: string
}

export interface SignupPayload {
  readonly firstName: string
  readonly lastName: string
  readonly email: string
  readonly name: string
  readonly invited?: string
  readonly user?: string
  readonly teamID?: string
}

export const defaultSignupState: SignupState = {
  signupFormData: {
    firstName: "",
    lastName: "",
    email: ""
  }
}
