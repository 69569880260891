import React, { useEffect } from "react"
import { connect } from "react-redux"
import { sitka, AppState } from "../../common/redux/sitka/sitka"
import {
  TeamModule,
  TeamState,
  InviteTeamFormValues
} from "../../common/redux/sitka/team/team_module"

import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import {
  webStrings as strings,
  inviteMembersToYourTeam
} from "../assets/web_strings"
import { constantStyles } from "../assets/style_consts"
import { TeamCreationShell } from "./team_creation_shell"
import { SessionState } from "../../common/redux/sitka/session/session_state"
import { useHistory } from "react-router"
import GoogleAnalytics from "react-ga"

const { StyledInput } = constantStyles
const {
  skipButtonText,
  sendInviteButtonText,
  teamMemberInputPlaceholder
} = strings.teamCreation

interface TeamInviteFormProps {
  readonly teamModule: TeamModule
  readonly team: TeamState
  readonly session: SessionState
}

interface TeamMemberFormProps {
  readonly teamName: string
  readonly handleSubmitInvites: (values: InviteTeamFormValues) => void
  readonly skipInvites: () => void
  readonly emailFrom: string
}

// console.log(`Path is ${this.props.path}`)
const emailFrom: string = "11111"

const yupSchema = Yup.object({
  email1: Yup.string().email("Please enter a valid email"),
  email2: Yup.string().email("Please enter a valid email"),
  email3: Yup.string().email("Please enter a valid email"),
  email4: Yup.string().email("Please enter a valid email"),
  email5: Yup.string().email("Please enter a valid email"),
  emailfrom: Yup.string()
})
  .test("one-invitee", "You must provide at least one email", function(value) {
    const isOneInvitee = !!(
      value.email1 ||
      value.email2 ||
      value.email3 ||
      value.email4 ||
      value.email5
    )
    return isOneInvitee
      ? true
      : this.createError({
          path: "oneInvitee",
          message: "You must provide at least one email"
        })
  })
  .test("no-self-email", "There is no need to invite yourself", function(
    value
  ) {
    const email = value.emailFrom.emailFrom.toLowerCase()
    const noSelfEmail = !(
      (value.email1 && value.email1.toLowerCase() == email) ||
      (value.email2 && value.email2.toLowerCase() == email) ||
      (value.email3 && value.email3.toLowerCase() == email) ||
      (value.email4 && value.email4.toLowerCase() == email) ||
      (value.email5 && value.email5.toLowerCase() == email)
    )

    return noSelfEmail
      ? true
      : this.createError({
          path: "noSelfEmail",
          message: "There is no need to invite yourself"
        })
  })

const TeamMemberForm: React.FC<TeamMemberFormProps> = ({
  teamName,
  handleSubmitInvites,
  skipInvites,
  emailFrom
}) => (
  <div className="team-email-form">
    <Formik
      initialValues={{
        email1: "",
        email2: "",
        email3: "",
        email4: "",
        email5: "",
        emailFrom: { emailFrom },
        oneInvitee: "",
        noSelfEmail: ""
      }}
      validationSchema={yupSchema}
      onSubmit={(values): void => {
        handleSubmitInvites(values)
      }}
    >
      {({ errors, touched }): JSX.Element => (
        <Form>
          <Field
            name="emailFrom"
            type="hidden"
            className="team-invite-input"
            value={emailFrom}
          />
          <StyledInput>
            <label>{inviteMembersToYourTeam(teamName)}</label>
            <label htmlFor="email1">
              <span className="error-message">
                <ErrorMessage name="email1" />
                {(errors.oneInvitee || errors.noSelfEmail) && touched && (
                  <div>
                    {errors.oneInvitee}
                    {errors.noSelfEmail}
                  </div>
                )}
              </span>
            </label>
            <Field
              name="email1"
              type="text"
              className="team-invite-input"
              placeholder={teamMemberInputPlaceholder}
            />
            <label htmlFor="email2">
              <span className="error-message">
                <ErrorMessage name="email2" />
              </span>
            </label>
            <Field
              name="email2"
              type="text"
              className="team-invite-input"
              placeholder={teamMemberInputPlaceholder}
            />
            <label htmlFor="email3">
              <span className="error-message">
                <ErrorMessage name="email3" />
              </span>
            </label>
            <Field
              name="email3"
              type="text"
              className="team-invite-input"
              placeholder={teamMemberInputPlaceholder}
            />
            <label htmlFor="email4">
              <span className="error-message">
                <ErrorMessage name="email4" />
              </span>
            </label>
            <Field
              name="email4"
              type="text"
              className="team-invite-input"
              placeholder={teamMemberInputPlaceholder}
            />
            <label htmlFor="email5">
              <span className="error-message">
                <ErrorMessage name="email5" />
              </span>
            </label>
            <Field
              name="email5"
              type="text"
              className="team-invite-input"
              placeholder={teamMemberInputPlaceholder}
            />
          </StyledInput>
          <div className="button-wrapper">
            <button
              onClick={(): void => {
                GoogleAnalytics.event({
                  category: "User",
                  action: "Click - skip invites"
                })
                skipInvites()
              }}
              className="gray button"
            >
              {skipButtonText}
            </button>
            <button type="submit" className="orange button">
              {sendInviteButtonText}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  </div>
)

export const TeamInviteForm: React.FC<TeamInviteFormProps> = ({
  session,
  team,
  teamModule
}) => {
  const { userFirstName } = session
  const { userLastName } = session
  const { email } = session
  const { teamName } = team
  const { handleSendInvites } = teamModule

  const history = useHistory()

  useEffect(() => {
    GoogleAnalytics.pageview("invite-team")
  }, [])

  const handleSubmitInvites = (values: InviteTeamFormValues): void => {
    handleSendInvites(values)
    // set to a flag like signup
    history.push("/get-started")
  }

  const skipInvites = (): void => {
    history.push("/get-started")
  }

  return (
    <TeamCreationShell userName={userFirstName} lastName={userLastName}>
      <TeamMemberForm
        teamName={teamName}
        handleSubmitInvites={handleSubmitInvites}
        skipInvites={skipInvites}
        emailFrom={email}
      />
    </TeamCreationShell>
  )
}
export default connect((state: AppState) => {
  const modules = sitka.getModules()
  const { team, session } = state
  const { team: teamModule } = modules

  return {
    team,
    session,
    teamModule
  }
})(TeamInviteForm)
