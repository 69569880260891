import { SitkaModule } from "olio-sitka"
import { AppModules, AppState } from "../sitka"
import * as firebase from "firebase/app"
import { fbConfig } from "../../../firebase_config"
import { select, put, call } from "redux-saga/effects"
import {
  defaultBootstrapState,
  BootstrapState,
  PathContext
} from "./bootstrap_state"
import {
  getWindowContext,
  parseQueryParams
} from "../../../util/bootstrap_utils"

export class BootstrapModule extends SitkaModule<{}, AppModules> {
  public moduleName = "bootstrap"
  public defaultState = defaultBootstrapState

  // getters
  public getBootstrapState(state: AppState): BootstrapState {
    return state.bootstrap
  }

  public getPathContext(state: AppState): PathContext {
    return state.bootstrap.pathContext
  }

  // setters
  public *handleSetIsBootstrapped(isBootstrapped: boolean): {} {
    const state: BootstrapState = yield select(this.getBootstrapState)
    yield put(this.setState({ ...state, isBootstrapped }))
  }
  public *handleSetPathContext(pathContext: PathContext): {} {
    const state: BootstrapState = yield select(this.getBootstrapState)
    yield put(this.setState({ ...state, pathContext }))
  }
  // Bootstrap Functions
  public *handleBootstrap(): {} {
    const { redirect, session, signup } = this.modules
    const { signupFormData } = yield select(signup.selectSignupState)
    const path: string = getWindowContext(window)
    const pathContext: PathContext = parseQueryParams(path)

    yield call(session.handleSetDataInFlight, true)

    window.addEventListener("message", redirect.receiveMsg, false)

    firebase.initializeApp(fbConfig)
    yield call(session.handleAttemptSignInFromGoogleAuth)
    firebase
      .auth()
      .onAuthStateChanged(user => session.handleAuthStateChange(user))

    if (path && pathContext.invited) {
      signup.handleSetNewFormState({
        ...signupFormData,
        email: pathContext.invited
      })
    }

    yield call(this.handleSetIsBootstrapped, true)
    yield call(this.handleSetPathContext, pathContext)
  }
}
