import { Sitka } from "olio-sitka"
import { Store } from "redux"
import { SessionModule } from "./session/session_module"
import { SessionState } from "./session/session_state"
import { SignupModule } from "./signup/signup_module"
import { SignupState } from "./signup/signup_state"
import { BootstrapModule } from "./bootstrap/bootstrap_module"
import { BootstrapState } from "./bootstrap/bootstrap_state"
import { TeamModule, TeamState } from "./team/team_module"
import { RedirectModule } from "./redirect/redirect_module"

export interface AppModules {
  readonly session: SessionModule
  readonly signup: SignupModule
  readonly bootstrap: BootstrapModule
  readonly team: TeamModule
  readonly redirect: RedirectModule
}

export interface AppState {
  readonly session: SessionState
  readonly signup: SignupState
  readonly bootstrap: BootstrapState
  readonly team: TeamState
}

export const sitka = new Sitka<AppModules>({
  log: true,
  sitkaInState: false
})

sitka.register([
  new SessionModule(),
  new SignupModule(),
  new BootstrapModule(),
  new TeamModule(),
  new RedirectModule()
])

export const store: Store = sitka.createStore() as Store
