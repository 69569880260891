export const webStrings = {
  signupHeader: {
    header: "Create your Cardsmith account",
    andGetA: "and get a",
    freeTrial: "FREE 30-day trial ",
    ofCardsmithPro: "of Cardsmith Pro!",
    or: "OR",
    signUpEmail: "Sign up with email"
  },
  signupFormLabels: {
    email: "EMAIL ADDRESS",
    firstName: "FIRST NAME",
    lastName: "LAST NAME",
    userName: "USER NAME",
    password: "PASSWORD",
    signUp: "SIGN UP",
    googleSignUp: "Sign up with Google",
    byClicking: "By clicking the button below, you are agreeing to our ",
    tos: "Terms of Service ",
    and: "and ",
    privacyPolicy: "Privacy Policy.",
    alreadyAUser: "Already a user? Log in."
  },
  signupErrors: {
    required: "Required",
    passwordMin: "(AT LEAST SIX CHARACTERS)",
    emailConsent:
      "Would you like to recieve occasional emails about how to get more value from Cardsmith?"
  },
  teamCreation: {
    accountHasBeenCreated: "Your account has been created!",
    useWithYourTeam: "Use Cardsmith with your team!",
    collaborateAnywhereAnytime: "Collaborate. Anywhere. Anytime.",
    description: `Everyone on the team can share ideas, anytime from anywhere in the world with a tool that is instantly familar.
    With real-time collaboration, all changes are immediately seen by the team.`,
    teamNameInputLabel: "Tell us your team name",
    teamNameInputPlaceholder: "e.g. Our Great Squad",
    teamNameButtonText: "Create Team",
    planningOnUsingCardsmithAlone: "Planning to use Cardsmith on your own?",
    changeYourMind:
      "Cool. If you ever change your mind, you can add collaborators at any time in your account settings.",
    gotIt: "Got it. ",
    skipForNow: "Skip for now.",
    skipButtonText: "Skip for now",
    sendInviteButtonText: "Send invites",
    teamMemberInputPlaceholder: "coworker@yourcompany.com"
  },
  brochureContent: {
    yourFreeCardSmithAccount: "Your free Cardsmith account",
    comesWithA: "comes with a",
    freeTrial: "Free 30 day trial",
    ofCardsmithPro: "of CARDSMITH PROFESSIONAL",
    professionalPerks: "Professional includes:",
    perks: [
      "Unlimited boards & cards",
      "Grid, freeform & tile board views",
      "Real-time collaboration",
      "Public & private sharing of boards",
      "Export board to PDF",
      "Export board to CSV",
      "Comments on boards by collaborators"
    ],
    afterTrial: "At the end of 30 days, ",
    optionForProfessional: `you'll have the option to continue with Professional ($12 per month or $108 per year) or revert to a free account.`
  },
  getStarted: {
    yourTeamHasBeenInvited:
      "Your team members have been invited via email and we'll help them get signed up!",
    quickTipsSubheader: "Check out some quick tips, then get started!",
    buttonText: "Got it! Let's do this!"
  },
  carouselContent: [
    {
      header: "Welcome to Cardsmith, a wide open space to brainstorm",
      content:
        "Create a board by clicking on the plus sign. To create a card simply press and hold or hit enter."
    },
    {
      header: "Customize Your Cards",
      content:
        "Hover over a card and click on the bottom right navigation for options. Connectors link your cards to easily see associations and dependencies. Change card colors and add icons."
    },
    {
      header: "Add Content To Cards",
      content:
        "Clicking on a card allows you to add text fields, checklists, images, and links."
    },
    {
      header: "Add images",
      content:
        "If a link has meta information it will unfurl an image for a rich visual cue. A thumbnail image will show for the first image or link on a card."
    },
    {
      header: "Switch Views For Organization",
      content:
        "Switch to grid view in the navigation to quickly move cards into columns and rows. Going back to freeform view will retain your cards’ positions so you can brainstorm and manage projects in the same board."
    },
    {
      header: "Grid Totals For Projects",
      content:
        "Create grid totals with a title in a text label and a number value in the text field. By setting the card as a default card you can add values and see totals for grids and/or rows."
    },
    {
      header: "Share With Your Team",
      content:
        "Cardsmith works best collaborating! Add a team member or client to your board in the navigation. You can set edit permissions for each invite. Or share publicly for anyone with a link to see."
    },
    {
      header: "Leave Board Comments",
      content:
        "Shared boards will show up below your personal boards. Leave comments for your team or yourself."
    },
    {
      header: "View Board Activity and Changes",
      content:
        "When a board has changed there will be an extra border letting you know to check it out. View the board activity to see exactly what your team has been up to. It’s also a handy way to jump back in where you left off."
    }
  ],
  links: {
    // todo: refactor these when we know how user/session id fits into the url
    termsOfService: "https://cardsmith.co/terms/",
    privacyPolicy: "https://cardsmith.co/privacypolicy/"
  }
}

export const welcomeMessage = (name: string): string => `Welcome, ${name}!`
export const inviteMembersToYourTeam = (teamName: string): string =>
  `INVITE YOUR COWORKERS TO ${teamName}:`
export const getStartedHeader = (name: string): string =>
  `You're all set, ${name}!`
