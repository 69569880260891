import { PathContext } from "../redux/sitka/bootstrap/bootstrap_state"

export const getWindowContext = ({ location }: Window): string => {
  const { search } = location
  return search
}

// parse params out of a url that looks like this
// https://{server address}/{anything you all need as URL}?invite={email uf the invited user}&user={UID of team creator}{any params you all would need}
export const parseQueryParams = (path: string): PathContext => {
  return path
    ? (/^[?#]/.test(path) ? path.slice(1) : path)
        .split("&")
        .reduce((params, param) => {
          const [key, value] = param.split("=")
          return {
            ...params,
            [key]: value
          }
        }, {})
    : {}
}
