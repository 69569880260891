import {
  SignupFormData,
  SignupPayload
} from "../redux/sitka/signup/signup_state"
import { PathContext } from "../redux/sitka/bootstrap/bootstrap_state"

export const buildNewUserPayload = (
  values: SignupFormData,
  pathContext: PathContext
): SignupPayload => {
  const { email, firstName, lastName } = values
  let urlParams = pathContext as any
  let referralTags = {}
  Object.keys(urlParams).forEach(param => {
    if (param.startsWith("utm_")) {
      let tag = { [param]: urlParams[param] }
      Object.assign(referralTags, tag)
    }
  })

  if (pathContext.invited && pathContext.teamID) {
    const { invited, user, teamID } = pathContext
    const signupData = {
      email: email.toLowerCase(),
      name: `${firstName} ${lastName}`,
      firstName,
      lastName,
      invited,
      user,
      teamID
    }
    if (Object.keys(referralTags).length > 0)
      Object.assign(signupData, { tags: referralTags })
    return signupData
  }

  const signupData = {
    email,
    name: `${firstName} ${lastName}`,
    firstName,
    lastName
  }
  if (Object.keys(referralTags).length > 0)
    Object.assign(signupData, { tags: referralTags })
  return signupData
}
