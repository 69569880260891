import { Error } from "../../common/redux/sitka/session/session_state"

export const errorMessage = (error: Error) => {
  switch (error) {
    case "USER ALREADY EXISTS":
      return "This user already has a Cardsmith account. Please log in."
    default:
      return ""
  }
}
