import React, { useEffect, useState } from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  RouteProps
} from "react-router-dom"
import SignUpForm from "./web/components/signup_form"
import TeamInviteForm from "./web/components/invite_team_form"
import TeamNameForm from "./web/components/team_name_form"
import GetStartedInfo from "./web/components/get_started"
import GoogleAuth from "./web/components/google_auth"
import styled from "styled-components"
import { appColors } from "./web/assets/app_colors"
import { connect } from "react-redux"
import { sitka, AppState } from "./common/redux/sitka/sitka"
import { BootstrapModule } from "./common/redux/sitka/bootstrap/bootstrap_module"
import { BootstrapState } from "./common/redux/sitka/bootstrap/bootstrap_state"
import { SessionModule } from "./common/redux/sitka/session/session_module"
import spinner from "./web/assets/images/spinner.svg"
import { SessionState } from "./common/redux/sitka/session/session_state"
import { isProd } from "./common/config"
import GoogleAnalytics from "react-ga"

const redirectIframeDomain = process.env.REACT_APP_CARDSMITH_APP_URL
const googleAnalyticsKey = process.env.REACT_APP_CARDSMITH_GA_KEY || ""

interface AppProps {
  readonly bootstrapModule: BootstrapModule
  readonly bootstrapState: BootstrapState
  readonly sessionModule: SessionModule
  readonly sessionState: SessionState
}

interface PrivateRouteProps extends RouteProps {
  readonly uid: string
  readonly path: string
  readonly userDataHydrated: boolean
}

const PrivateRoute = ({
  children,
  uid,
  userDataHydrated
}: PrivateRouteProps) => {
  return uid && userDataHydrated ? (
    <Route>{children}</Route>
  ) : (
    <Redirect to="/signup" />
  )
}

// setup google analytics
GoogleAnalytics.initialize(googleAnalyticsKey)

const logout = () => {
  const { session } = sitka.getModules()
  session.logOut()
}

let spinnerTimer = 0

const App: React.FC<AppProps> = ({
  bootstrapModule,
  bootstrapState,
  sessionState
}) => {
  const { handleBootstrap } = bootstrapModule
  const { isBootstrapped } = bootstrapState
  const { dataInFlight, userFirstName, userLastName, uid } = sessionState
  const userDataHydrated = !!userFirstName
  const showDevButtons = !isProd

  const [showSpinner, setSpinner] = useState(true)

  useEffect(() => {
    handleBootstrap()
  }, [handleBootstrap])

  useEffect(() => {
    if (showSpinner === false && dataInFlight) {
      spinnerTimer = setTimeout(() => {
        setSpinner(true)
      }, 500)
    }
    if (dataInFlight === false) {
      clearTimeout(spinnerTimer)
      setSpinner(false)
    }
  }, [dataInFlight])

  return (
    <AppContainer>
      {showSpinner && (
        <div className="overlay">
          <img className="spinner" src={spinner} alt="Loading spinner" />
        </div>
      )}
      <Router>
        <Switch>
          <Route path="/signup">{isBootstrapped && <SignUpForm />}</Route>
          <Route path="/googleauth">{isBootstrapped && <GoogleAuth />}</Route>
          <PrivateRoute
            userDataHydrated={userDataHydrated}
            uid={uid}
            path="/create-team"
          >
            <TeamNameForm />
          </PrivateRoute>
          <PrivateRoute
            userDataHydrated={userDataHydrated}
            uid={uid}
            path="/invite-team"
          >
            <TeamInviteForm />
          </PrivateRoute>
          <PrivateRoute
            userDataHydrated={userDataHydrated}
            uid={uid}
            path="/get-started"
          >
            <GetStartedInfo />
          </PrivateRoute>
          <Redirect to="/signup" />
        </Switch>
      </Router>
      {showDevButtons && <button onClick={() => logout()}>Log out FB</button>}
    </AppContainer>
  )
}

export default connect((state: AppState) => {
  const modules = sitka.getModules()
  return {
    bootstrapModule: modules.bootstrap,
    bootstrapState: state.bootstrap,
    sessionModule: modules.session,
    sessionState: state.session
  }
})(App)

const AppContainer = styled.div`
  min-height: 100%;
  min-width: 100%;
  background: ${appColors.portalBackground};
  position: absolute;

  .overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    z-index: 100;
    background: rgba(0, 0, 0, 0.5);
  }
  img.spinner {
    display: block;
    height: calc(0.5 * 325px);
    margin: 0 auto 16px auto;
    animation-duration: 800ms;
    animation-name: rotate;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }

  iframe {
    display: none;
  }
`
