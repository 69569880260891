import { SitkaModule } from "olio-sitka"
import { put, select, call } from "redux-saga/effects"
import { AppModules, AppState } from "../sitka"
import * as firebase from "firebase/app"
import "firebase/database"
import "firebase/auth"
import { SessionModule } from "../session/session_module"
import { SessionState } from "../session/session_state"
import * as Client from "../../../api/client"
import GoogleAnalytics from "react-ga"

const server = process.env.REACT_APP_EMAIL_TEMPLATE_SERVER
const signupServer = process.env.REACT_APP_SIGNUP_APP_URL

export interface TeamState {
  readonly teamName: string
  readonly teamKey: string
  readonly teamInvites: ReadonlyArray<string>
  readonly invitesSent: boolean
}

export interface InviteTeamFormValues {
  readonly email1: string
  readonly email2: string
  readonly email3: string
  readonly email4: string
  readonly email5: string
  emailFrom: object
}

export interface CreateTeamValues {
  readonly teamName: string
}

export interface TeamCreationPayload {
  info: { title: string }
  parent: string
  users: { [parent: string]: { role: string } }
}

const defaultTeamState = {
  teamName: "",
  teamKey: "",
  teamInvites: [],
  invitesSent: false
}

export class TeamModule extends SitkaModule<TeamState, AppModules> {
  public moduleName = "team"
  public defaultState: TeamState = defaultTeamState

  // getters
  public static getTeam(state: AppState): TeamState {
    return state.team
  }

  public static getTeamName(state: AppState): string {
    return state.team.teamName
  }

  public static getTeamInvites(state: AppState): ReadonlyArray<string> {
    return state.team.teamInvites
  }

  // setters
  public *handleSetTeamName(teamName: string): {} {
    const teamState: TeamState = yield select(TeamModule.getTeam)
    yield put(this.setState({ ...teamState, teamName }))
  }

  public *handleSetInvitesSent(invitesSent: boolean): {} {
    const teamState: TeamState = yield select(TeamModule.getTeam)
    yield put(this.setState({ ...teamState, invitesSent }))
  }

  public *handleSetTeamKey(teamKey: string): {} {
    const teamState: TeamState = yield select(TeamModule.getTeam)
    yield put(this.setState({ ...teamState, teamKey }))
  }

  public *handleSetTeamInvites(teamInvites: []): {} {
    const teamState: TeamState = yield select(TeamModule.getTeam)
    yield put(this.setState({ ...teamState, teamInvites }))
  }

  public *handleClearTeamState(): {} {
    yield put(this.setState({ ...defaultTeamState }))
  }

  // Team utils
  public *handleSendInvites(values: InviteTeamFormValues): {} {
    GoogleAnalytics.event({
      category: "User",
      action: "Click - send team invite"
    })

    const signup = this.modules.signup
    delete values.emailFrom
    const invitesArray = this.transformInvitesToArray(values)
    yield call(this.handleSetTeamInvites, invitesArray)
    const emailFrom: string = yield select(signup.selectUserEmail)
    const sessionState: SessionState = yield select(SessionModule.getSession)
    const { uid, userFirstName: firstName } = sessionState
    const { teamName, teamKey } = yield select(TeamModule.getTeam)
    this.sendInvites(invitesArray, uid, firstName, teamKey, teamName, emailFrom)
    console.log(`Sending team invites`)
    yield call(this.handleSetInvitesSent, true)
  }

  public *handleCreateTeam(values: CreateTeamValues): {} {
    const { teamName } = values
    yield call(this.handleSetTeamName, teamName)
    const sessionState: SessionState = yield select(SessionModule.getSession)
    const { uid } = sessionState
    const payload = yield call(Client.buildTeamCreationPayload, teamName, uid)
    this.writeTeamToFireBase(payload)
  }

  private writeTeamToFireBase(payload: TeamCreationPayload): void {
    const db = firebase.database()
    db.ref("/teams")
      .push(payload, error => {
        // todo - a better error messaging solution
        if (error) {
          console.log("team creation error", error)
        }
      })
      .then(ref => {
        if (ref.key) {
          const teamKey = ref.key
          this.handleSetTeamKey(teamKey)
        }
      })
  }

  private sendInvites(
    inviteEmails: string[],
    uid: string,
    firstName: string,
    teamKey: string,
    teamName: string,
    emailFrom: string
  ): void {
    if (inviteEmails.length > 0) {
      inviteEmails.forEach(emailTo => {
        console.log("wrote email", emailTo)
        this.writeInviteToFirebase(
          emailTo,
          uid,
          firstName,
          teamKey,
          teamName,
          emailFrom
        )
      })
    }
  }

  private writeInviteToFirebase(
    emailTo: string,
    uid: string,
    firstName: string,
    teamKey: string,
    teamName: string,
    emailFrom: string
  ): void {
    const invite = {
      emailTo: emailTo.toLowerCase(),
      type: "signup",
      created: firebase.database.ServerValue.TIMESTAMP
    }
    const db = firebase.database()
    db.ref("teams/" + teamKey + "/invites")
      .push(invite, error => {
        if (error) {
          // todo - a better error messaging solution
          console.log("write Invite to FB error", error)
        }
      })
      .then(() => {
        this.writeTeamInviteEmailToMailQ(
          emailTo,
          uid,
          firstName,
          teamKey,
          teamName,
          emailFrom
        )
      })
  }

  private writeTeamInviteEmailToMailQ(
    emailTo: string,
    uid: string,
    firstName: string,
    teamKey: string,
    teamName: string,
    emailFrom: string
  ): void {
    const db = firebase.database()
    const templatePayload = {
      command: "send-template",
      params: {
        // eslint-disable-next-line @typescript-eslint/camelcase
        template_name: "team-invite-onboard-2020",
        // eslint-disable-next-line @typescript-eslint/camelcase
        uid_from: `${uid}`,
        // eslint-disable-next-line @typescript-eslint/camelcase
        email_to: `${emailTo}`,
        content: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          name_from: firstName,
          // eslint-disable-next-line @typescript-eslint/camelcase
          email_from: `${emailFrom}`,
          // eslint-disable-next-line @typescript-eslint/camelcase
          name_to: `${emailTo}`,
          // eslint-disable-next-line @typescript-eslint/camelcase
          from_user_key: `${uid}`,
          // eslint-disable-next-line @typescript-eslint/camelcase
          team_name: `${teamName}`,
          // eslint-disable-next-line @typescript-eslint/camelcase
          team_id: `${teamKey}`,
          email_to: `${emailTo}`,
          signupServer,
          server
        }
      },
      status: "created",
      created: firebase.database.ServerValue.TIMESTAMP
    }
    db.ref("mailQ").push(templatePayload, error => {
      // todo - a better error messaging solution
      if (error) console.log("mailQ write error", error)
    })
  }

  private transformInvitesToArray(values: InviteTeamFormValues): [] {
    const teamInvites = Object.values(values)
    return teamInvites.reduce((acc, email) => {
      if (email) {
        let emailLC = email.toLowerCase()
        if (!acc.includes(emailLC)) {
          return [...acc, email.toLowerCase()]
        }
      }
      return acc
    }, [])
  }

  private get(fbRef: firebase.database.Reference): Promise<string> {
    return new Promise((resolve, reject) => {
      fbRef.once("value", ss => resolve(ss.val()), reject)
    })
  }
}
