import styled, { css } from "styled-components"
import { appColors } from "./app_colors"

export const mq = {
  desktop: (...args: ReadonlyArray<any>): any => css`
    @media (min-width: 420px) {
      // @ts-ignore
      ${css(...args)};
    }
  `,
  desktopBrochure: (...args: ReadonlyArray<any>): any => css`
    @media (min-width: 900px) {
      // @ts-ignore
      ${css(...args)};
    }
  `
}

export const constantStyles = {
  padding: {
    padding48: css`
      padding: 10%;
      ${mq.desktop`
        padding: 48px;
      `}
    `
  },
  margin: {
    margin60: css`
      ${mq.desktop`
        margin: 60px auto 0 auto;
      `}
    `
  },
  sections: {
    headerSection: css`
      h1 {
      }
    `
  },
  textStyles: css`
    h1,
    p,
    label {
      color: ${appColors.signupModalText};
    }
    h1 {
      font-family: "proxima-nova", sans-serif;
    }
    p,
    label {
      font-family: "proxima-nova", sans-serif;
    }
    .emphasis {
      color: ${appColors.signupModalTextEmphasis};
    }
    .link {
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .bold {
      font-weight: bold;
    }
    .all-caps {
      text-transform: uppercase;
    }
  `,
  sectionStyles: css`
    .container,
    form {
      border-bottom: 2px dotted ${appColors.signupModalText};
    }

    .header {
      padding-bottom: 20px;

      h1 {
        font-size: 1.25em;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
      p {
        font-size: 1em;
      }
      h1,
      p {
        text-align: center;
      }
    }
    .footer {
      padding-top: 20px;
      p {
        font-size: 0.8em;
        text-align: center;
      }
      p.bold {
        font-size: 1.1em;
        margin-bottom: 20px;
      }
      p.multiline {
        font-size: 0.9em;
        margin-bottom: 20px;
      }
      p.confirm-skip {
        font-size: 1em;
      }
    }
  `,
  buttonStyles: css`
    .button {
      border: 0;
      font-size: 1em;
      font-weight: bold;
      text-transform: uppercase;
    }
    .orange {
      background: ${appColors.signUpButton};
      color: ${appColors.signupModalBackground};
    }
    .gray {
      background: ${appColors.skipButtonBackground};
      color: ${appColors.skipButtonText};
    }
  `,
  formStyles: css`
    background: ${appColors.signupModalBackground};
    max-width: 100%;
    overflow-y: auto;
    padding: 10%;
    .error-message {
      color: ${appColors.signupModalTextEmphasis};
    }
    form {
      span.error-message {
        &:before {
          content: " ";
        }
      }
    }
    ${mq.desktop`
      width: 480px;
      padding: 48px;
    `};
  `,
  StyledInput: styled.div`
    padding: 10px 0;
    display: flex;
    flex-direction: column;

    label {
      text-align: left;
      font-size: 0.7em;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: 2px;
      color: ${appColors.signupModalText};
    }

    input {
      width: 100%;
      padding: 0.8em;
      outline: 0;
      font-size: 0.9em;
      color: ${appColors.signupModalText};
      margin: 3px 0;
      letter-spacing: 0.25px;
      border: 1.2px solid ${appColors.signupModalText};
      transition: all 0.25s ease-in-out;
      &:focus {
        box-shadow: 0 0 5px ${appColors.signupInputFocusBoxShadow};
        background: ${appColors.signupInputFocusBackground};
      }
    }
    input::placeholder {
      color: ${appColors.inputPlaceholder};
    }
    ::-webkit-input-placeholder {
      color: ${appColors.inputPlaceholder};
    }

    :-ms-input-placeholder {
      color: ${appColors.inputPlaceholder};
    }
  `
}
