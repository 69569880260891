import { SitkaModule } from "olio-sitka"
import { call, put, select } from "redux-saga/effects"
import { AppModules, AppState } from "../sitka"
import {
  SignupState,
  defaultSignupState,
  SignupFormData,
  SignupStateValues,
  SignupPayload
} from "./signup_state"
import { PathContext } from "../bootstrap/bootstrap_state"
import { buildNewUserPayload } from "../../../util/signup_utils"
import * as firebase from "firebase/app"
import "firebase/database"
import "firebase/auth"

const server = process.env.REACT_APP_EMAIL_TEMPLATE_SERVER

declare global {
  interface Window {
    GTM: any
  }
}

let GTM = window.GTM

interface Credentials {
  readonly email: string
  readonly password: string
}

export interface GoogleUserData {
  readonly firstName: string
  readonly lastName: string
  readonly email: string
  readonly uid: string
}

export class SignupModule extends SitkaModule<SignupState, AppModules> {
  public moduleName = "signup"
  public defaultState: SignupState = defaultSignupState

  // getters
  public selectUserEmail(state: AppState): string {
    const { email } = state.signup.signupFormData
    return email
  }

  public selectSignupState(state: AppState): SignupState {
    return state.signup
  }

  // setters
  public *handleSetNewFormState(values: SignupStateValues): {} {
    const { firstName, lastName, email } = values
    const signupState: SignupState = yield select(this.selectSignupState)

    const signupStateValues: SignupStateValues = {
      firstName,
      lastName,
      email
    }
    yield put(
      this.setState({ ...signupState, signupFormData: signupStateValues })
    )
  }

  public *handleClearSignupState(): {} {
    yield put(this.setState({ ...defaultSignupState }))
  }

  // Firebase Actions

  public *handleRegisterNewUser(values: SignupFormData): {} {
    const { session, bootstrap } = this.modules
    yield call(session.handleClearError)
    yield call(session.handleLogOut)
    this.handleSetNewFormState(values)
    const { email, password } = values
    const pathContext: PathContext = yield select(bootstrap.getPathContext)

    const signupData = buildNewUserPayload(values, pathContext)

    const auth = firebase.auth()
    auth
      .createUserWithEmailAndPassword(email, password)
      .then(authUserCredential => {
        if (authUserCredential && authUserCredential.user) {
          const { uid } = authUserCredential.user
          console.log(`user cred: ${JSON.stringify(authUserCredential)}`)
          this.writeUserToFirebase(uid, signupData)
        }
      })
      .catch(error => {
        console.log(`signuperror: ${error}`)
        session.logOutWithError("USER ALREADY EXISTS")
      })
      .then(() => {
        const trackConversion = (window as any).google_trackConversion
        //Per Faye - adwords
        trackConversion({
          google_conversion_id: 846713902,
          google_conversion_language: "en",
          google_conversion_format: "3",
          google_conversion_color: "ffffff",
          google_conversion_label: "ZqJmCPGq-nMQrqjfkwM",
          google_remarketing_only: false
        })
      })
  }

  public handleRegisterNewGoogleUser(values: GoogleUserData): void {
    const { firstName, lastName, email, uid } = values
    const signupData = {
      email,
      name: `${firstName} ${lastName}`,
      firstName: firstName,
      lastName: lastName
    }
    this.writeUserToFirebase(uid, signupData)
  }

  private writeUserToFirebase(uid: string, signupData: SignupPayload): void {
    const db = firebase.database()
    const session = this.modules.session
    console.log(`signupdata: ${JSON.stringify(signupData)}`)
    console.log(`uid: ${uid}`)
    db.ref("signups/" + uid)
      .set(signupData, error => {
        // todo - a better error messaging solution
        if (error) {
          alert("ERROR: Signup may not have succeeded: " + error)
        }
      })
      .then(() => {
        // calling handleSetSessionAuth here sets state.session.uid, which pushes the user to /create-team
        const { firstName, lastName, email } = signupData
        session.handleSetSessionAuth(
          firstName,
          lastName,
          uid,
          email.toLowerCase()
        )
      })
  }

  // Read Firebase value from firebase database ref object
  private get(fbRef: firebase.database.Reference): Promise<string> {
    return new Promise((resolve, reject) => {
      fbRef.once("value", ss => resolve(ss.val()), reject)
    })
  }
}
