const colors = {
  breakerBay: "#5B9399",
  white: "#FFFFFF",
  fuscousGray: "#5c544f",
  treePoppy: "#f79420",
  silverSand: "#BCC1C3",
  cottonSeed: "#bfbbb8",
  serenade: "#fff7ed",
  gallery: "#EAEAEA",
  gray: "#7F7F7F",
  ecstasy: "#F6851D"
}

export const appColors = {
  portalBackground: colors.breakerBay,
  signUpButton: colors.treePoppy,
  signupModalBackground: colors.white,
  signupModalText: colors.fuscousGray,
  signupModalTextEmphasis: colors.treePoppy,
  signupModalGoogleButtonBorder: colors.silverSand,
  signupInputFocusBackground: colors.serenade,
  signupInputFocusBoxShadow: colors.cottonSeed,
  signUpInfoBackground: colors.gallery,
  skipButtonBackground: colors.gray,
  skipButtonText: colors.white,
  inputPlaceholder: colors.gallery,
  getStartedEmphasisText: colors.ecstasy
}
